<template>
  <div class="viewDashBoard project-show">
    <div class="container">
      <div
        class="white-row"
        v-if="
          typeof getActiveUserData.user !== 'undefined' &&
            Object.keys(getActiveUserData.user).length
        "
      >
        <div
          class="project-show-details"
          v-if="(getActiveUserData.userRoles == 'admin') || (getActiveUserData.userRoles == 'subadmin')"
        >
          <!-- <div class="main-title-header">
            <div class="row">
              <div class="col-sm-9">
                <h4>
                  Project #1078 -
                  <span>
                    Email Testing Employee Portal Testing
                  </span>
                </h4>
              </div>
              <div class="col-sm-3">
                <div class="top-date text-right">
                  <p>Posted on July 16 , 9:27 am</p>
                </div>
              </div>
            </div>
          </div> -->
          <div class="project-nav-area">
            <nav>
              <ul>
                <router-link
                  :to="{ name: 'settings.company.settings' }"
                  tag="li"
                >
                  Company Settings
                </router-link>
                <router-link
                  :to="{ name: 'settings.profile.settings' }"
                  tag="li"
                >
                  Profile Settings
                </router-link>
                <router-link
                  :to="{ name: 'settings.profile.currency' }"
                  tag="li"
                >
                  Currency Settings
                </router-link>
                <router-link
                  :to="{ name: 'settings.profile.roles.permission' }"
                  tag="li"
                >
                  Roles & Permissions
                </router-link>
              </ul>
            </nav>
          </div>
          <div class="project-content-area">
            <div class="inner-Router">
              <transition name="fade" mode="out-in">
                <router-view v-if="displaySubChildren"></router-view>
              </transition>
            </div>
          </div>
        </div>
        <not-found v-else></not-found>
      </div>
      <div v-else class="white-row loader-route">
        <div class="lds-sub-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import noComponent from "../../components/noComponentFound";
export default {
  components: {
    "not-found": noComponent
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  data() {
    return {
      displaySubChildren: true
    };
  },
  mounted() {
    document.title = this.$router.app._route.meta.pageTitle;
  },
  methods: {}
};
</script>
<style lang="scss">
.project-show {
  .project-nav-area {
    nav {
      ul {
        margin: 0;
        text-decoration: none;
        padding: 0;
        list-style: none;
        border-bottom: 2px solid #ebebeb;
        li {
          display: inline-grid;
          padding: 5px 30px 5px 0;
          position: relative;
          cursor: pointer;
          &:after {
            content: "";
            display: inline-block;
            height: 0;
            width: 100%;
            position: relative;
            bottom: -7px;
            left: 0px;
            border: 1px solid transparent;
          }
          &.router-link-exact-active {
            &:after {
              border: 1px solid #3fae2a;
            }
          }
        }
      }
    }
  }
  .main-title-header {
    padding: 0 0 30px;
    h4 {
      margin: 0;
      padding: 0;
      letter-spacing: 0px;
      color: #313f53;
      font-weight: 400;
      font-size: 24px;
      span {
        font-weight: bold;
      }
    }
    .top-date {
      p {
        margin: 0;
        padding: 6px 0 0;
        letter-spacing: 0.28px;
        color: #2a1c0b;
      }
    }
  }
  .project-content-area {
    padding: 30px 0 0px;
  }
}
</style>
